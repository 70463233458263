<template>
  <div class="goTop" @click="goTop()">
    <img src="@/assets/img/btn_top.png">
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    goTop() {
      let element = document.getElementById("app");
      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  }
}
</script>

<style scope>
.goTop {
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #666;
}
.goTop img {
  width: 36px;
  height: 36px;
}
</style>