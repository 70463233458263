import Vue from 'vue'
import VueRouter from 'vue-router'
import { discern } from '@/utils/discern.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: "首页",
    }
  },
  {
    path: '/AboutBingmo',
    name: 'AboutBingmo',
    component: () => import('@/views/AboutBingmo.vue'),
    meta: {
      title: "关于冰眸",
    }
  },
  {
    path: '/MerchantSettled',
    name: 'MerchantSettled',
    component: () => import('@/views/MerchantSettled.vue'),
    meta: {
      title: "程序展示",
    }
  },
  {
    path: '/News',
    name: 'News',
    component: () => import('@/views/News.vue'),
    meta: {
      title: "新闻资讯",
    }
  },
  {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: () => import('@/views/NewsDetails.vue'),
    meta: {
      title: "资讯详情",
    }
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('@/views/AboutUs.vue'),
    meta: {
      title: "介绍冰眸",
    }
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: () => import('@/views/ContactUs.vue'),
    meta: {
      title: "联系我们",
    }
  },
  // 移动端路由
  {
    path: '/HomeMobile',
    name: 'HomeMobile',
    component: () => import('@/viewMobile/HomeMobile.vue'),
    meta: {
      title: "移动端首页",
      type: "mobile"
    }
  },
  {
    path: '/AboutBingmoMobile',
    name: 'AboutBingmoMobile',
    component: () => import('@/viewMobile/AboutBingmoMobile.vue'),
    meta: {
      title: "关于冰眸",
      type: "mobile"
    }
  },
  {
    path: '/MerchantSettledMobile',
    name: 'MerchantSettledMobile',
    component: () => import('@/viewMobile/MerchantSettledMobile.vue'),
    meta: {
      title: "程序展示",
      type: "mobile"
    }
  },
  {
    path: '/NewsMobile',
    name: 'NewsMobile',
    component: () => import('@/viewMobile/NewsMobile.vue'),
    meta: {
      title: "新闻资讯",
      type: "mobile"
    }
  },
  {
    path: '/AboutUsMobile',
    name: 'AboutUsMobile',
    component: () => import('@/viewMobile/AboutUsMobile.vue'),
    meta: {
      title: "介绍冰眸",
      type: "mobile"
    }
  },
  {
    path: '/ContactUsMobile',
    name: 'ContactUsMobile',
    component: () => import('@/viewMobile/ContactUsMobile.vue'),
    meta: {
      title: "联系我们",
      type: "mobile"
    }
  },
  {
    path: '/NewsDetailsMobile',
    name: 'NewsDetailsMobile',
    component: () => import('@/viewMobile/NewsDetailsMobile.vue'),
    meta: {
      title: "资讯详情",
      type: "mobile"
    }
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (!discern()) {
    if (to.path != "/HomeMobile" && to.meta.type != "mobile") {
      next("/HomeMobile");
    }
  }
  next();
});

export default router
