<template>
  <div id="app">
    <!-- 公共头部 -->
    <div v-if="$route.meta.type != 'mobile'">
      <top></top>
    </div>
    <div v-else>
      <top-mobile></top-mobile>
    </div>
    <router-view
      :style="
        $route.meta.type != 'mobile' ? 'margin-top: 88px' : 'margin-top: 44px'
      "
    />
    <!-- 公共底部 -->
    <div v-if="$route.meta.type != 'mobile'">
      <bottom-nav></bottom-nav>
    </div>
    <div v-else>
      <bottom-mobile></bottom-mobile>
    </div>
    <!-- 返回顶部 -->
    <div v-if="$route.meta.type != 'mobile'">
      <go-top></go-top>
    </div>
  </div>
</template>

<script>
import top from "@/components/top.vue";
import goTop from "@/components/goTop.vue";
import BottomNav from "./components/bottomNav.vue";
import TopMobile from "./components/topMobile.vue";
import BottomMobile from "./components/bottomMobile.vue";
export default {
  components: {
    top,
    goTop,
    TopMobile,
    BottomMobile,
    BottomNav,
  },
};
</script>

<style>
article,
aside,
blockquote,
body,
button,
code,
dd,
details,
dl,
dt,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
input,
legend,
li,
menu,
nav,
ol,
p,
pre,
section,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "webfont";
  font-display: swap;
  src: url("//at.alicdn.com/t/webfont_nufnvd1etya.eot"); /* IE9*/
  src: url("//at.alicdn.com/t/webfont_nufnvd1etya.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("//at.alicdn.com/t/webfont_nufnvd1etya.woff2")
      format("woff2"),
    url("//at.alicdn.com/t/webfont_nufnvd1etya.woff") format("woff"),
    /* chrome、firefox */ url("//at.alicdn.com/t/webfont_nufnvd1etya.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("//at.alicdn.com/t/webfont_nufnvd1etya.svg#NotoSansHans-DemiLight")
      format("svg"); /* iOS 4.1- */
}
* {
  font-family: "webfont" !important;
}
</style>
