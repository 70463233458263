var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"iconfont icongengduo",staticStyle:{"font-size":"24px"},on:{"click":_vm.showPopup}})]),_c('div',[_c('van-popup',{style:({ height: '100%' }),attrs:{"position":"right"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"popupBox"},[_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"tabItem",style:(_vm.activeID == index ? 'color: #47B0B4;' : ''),on:{"click":function($event){return _vm.handleSwitchChange(index, item.path)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('div',{staticClass:"tabItem_active",style:(("top: " + (_vm.activeID * 54) + "px;")),on:{"click":function($event){return _vm.handleSwitchChange(
              _vm.activeID,
              _vm.activeID == 0
                ? '/HomeMobile'
                : _vm.activeID == 1
                ? '/AboutBingmoMobile'
                : _vm.activeID == 2
                ? '/MerchantSettledMobile'
                : _vm.activeID == 3
                ? '/NewsMobile'
                : _vm.activeID == 4
                ? '/AboutUsMobile'
                : _vm.activeID == 5
                ? '/AboutUsMobile'
                : ''
            )}}})],2)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("@/assets/img/link-tbdpu.png")}})])}]

export { render, staticRenderFns }