<template>
  <div class="bg">
    <div class="bottomBarClass">
      <!-- 联系我们 -->
      <div class="contactClass">
        <div class="titleClass">
          <span style="color: #dba989">▍</span>联系我们
        </div>
        <div class="contactClass_box">
          <!-- 固话与邮箱 -->
          <div class="contactClass_box_item">
            <div>
              <div>
                <img src="@/assets/img/pic-tel.png" />
              </div>
              <div>020-3203 9269</div>
            </div>
            <div>
              <div>
                <img src="@/assets/img/ic-email.png" />
              </div>
              <div>service@vlimsoft.com</div>
            </div>
          </div>
          <!-- 手机与微信 -->
          <div class="contactClass_box_item">
            <div>
              <div>
                <img src="@/assets/img/ic-iphone.png" />
              </div>
              <div>19924720960</div>
            </div>
            <div>
              <div>
                <img src="@/assets/img/ic-wechat.png" />
              </div>
              <div>VLimYK</div>
            </div>
          </div>
          <!-- 地址 -->
          <div>
            <div>
              <img src="@/assets/img/ic-adress.png" />
            </div>
            <div>广州市开发区科学城科研路12号生产力促进中心205、215、216</div>
          </div>
        </div>
      </div>
      <!-- 公众号 -->
      <div class="thePublic">
        <div class="titleClass">
          <span style="color: #dba989">▍</span>公众号
        </div>
        <div class="thePublic_img">
          <img src="@/assets/img/QRcode.png" />
        </div>
      </div>
    </div>
    <div class="record">
      <a href="http://www.vlimsoft.com/#/" target="_blank" rel="noopener noreferrer">
        <span>© {{year}} 微林软件</span>
      </a>
      ·
      <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
        <span>粤ICP备17068597号-6</span>
      </a>
      ·
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202001462" target="_blank" rel="noopener noreferrer">
        <img class="record_img" src="../assets/img/1.png">
        <span>
          粤公网安备 44011202001462 号
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.bg {
  background-color: #2e4849;
}
.record {
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.record_img {
  display: inline-block;
}
.record span {
  margin-left: 5px;
}
.record span:hover {
  border-bottom: 0.5px solid #fff;
}
.record a {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
}
.bottomBarClass {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  height: 320px;
  padding: 60px 0;
}
/* 联系我们 */
.contactClass {
  color: #fff;
}
.contactClass_box > div {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contactClass_box img {
  width: 24px;
  height: 24px;
  display: flex;
  object-fit: cover;
  margin-right: 20px;
}
.contactClass_box_item > div {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
/* 公众号 */
.thePublic {
  color: #fff;
}
.thePublic_img {
  width: 170px;
  height: 170px;
  margin: 30px 0;
}
.thePublic_img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: flex;
}
/* 标题样式 */
.titleClass {
  font-size: 24px;
  letter-spacing: 10px;
}
</style>