<template>
  <div class="footer">
    <div class="title">联系我们</div>
    <div class="infoBox">
      <div class="infoBox_left">
        <div>
          <div class="infoBox_leftImg iconfont icondianhua">
          </div>
          <div>020-3203 9269</div>
        </div>
        <div>
          <div class="infoBox_leftImg iconfont iconxiaoxi">
          </div>
          <div>17251@QQ.COM</div>
        </div>
        <div>
          <div class="infoBox_leftImg iconfont iconshouji">
          </div>
          <div>13829720960</div>
        </div>
        <div>
          <div class="infoBox_leftImg iconfont iconweixin">
          </div>
          <div>VLimYK</div>
        </div>
      </div>
      <div class="infoBox_right">
        <div>
          <img src="@/assets/img/QRcode.png" />
        </div>
        <div>公众号二维码</div>
      </div>
    </div>
    <div class="addressBox">
      <div class="addressBox_icon iconfont icondingwei"></div>
      <div>广州市开发区科学城科研路12号生产力促进中心205、215、216</div>
    </div>
    <div class="recordBox">
      <div>
        <a
          href="http://www.vlimsoft.com/#/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>© {{year}} 微林软件</span>
        </a>
        ·
        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>粤ICP备17068597号-6</span>
        </a>
      </div>
      <div>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011202001458"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img class="record_img" src="../assets/img/1.png" />
          <span> 粤公网安备 44011202001458 号 </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  color: #fff;
  padding: 40px;
  background-color: #2E4849;
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoBox {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.infoBox_left > div {
  padding: 4px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.infoBox_leftImg {
  margin-right: 8px;
  font-size: 24px;
} 
.infoBox_right img {
  width: 100px;
  height: 100px;
  display: flex;
  object-fit: cover;
  margin-bottom: 20px;
}
.addressBox {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.addressBox_icon {
  font-size: 24px;
  margin-right: 8px;
}
.recordBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.recordBox img {
  display: flex;
}
.recordBox a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
  color: #fff;
  text-decoration: none;
}
</style>